import React from 'react'
import './morePD.css'

const MorePD = () => (
  <div className="ulev_morePD section__padding" id="wgpt3">
    <div className="ulev_morePD-feature">
      <h4>Whether it is body products, food,<br /> fashion, entertainment, or the technology <br/>industry, our passion is to make your brand<br/> and products standout</h4>
    </div>
    <div className="ulev_morePD-container" style={{ marginLeft: 'auto' }}>
      <h4>We are always<br/> Looking for Great<br/>People</h4>
    </div>
    <div className="ulev_morePD-heading">
      <h1 className="explore__text">Explore our world</h1>
      <p>Explore more</p>
    </div>
  </div>
);

export default MorePD;