import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import { Footer } from './containers';
import { Navbar, Preloader } from './@/components';
import ReactGA from "react-ga4";
import { collection, getDocs } from 'firebase/firestore';
import { db } from "./firebase";
import LazyLoad from 'react-lazyload';

import Home from './screens/home/Home';
import Work from './screens/work/Work';
import TeamDetails from './screens/teamDetails/TeamDetails';
import Learn from './screens/learn/Learn';
import Contact from './screens/contact/Contact';
import About from './screens/about/About';
import Project1Details from './screens/projectDetails/ideal-farmers-yought-recognition-awards';
import Project2Details from './screens/projectDetails/apprey-public-relations-pr';
import Project3Details from './screens/projectDetails/silicon-mountain-conference-2023';
import Project4Details from './screens/projectDetails/Lewa';
import Project5Details from './screens/projectDetails/editi';
import Project6Details from './screens/projectDetails/purpose';
import Project7Details from './screens/projectDetails/alani';
import Project8Details from './screens/projectDetails/mayen-spices';
import Project9Details from './screens/projectDetails/ulevus-brand-book-version-1-0';
import Project10Details from './screens/projectDetails/Project10Details';
import Project11Details from './screens/projectDetails/african-rooted';
import Project0Details from './screens/projectDetails/echolink-solutions';
import Project12Details from './screens/projectDetails/sontech';
import Project13Details from './screens/projectDetails/house-of-sigala';
import Project14Details from './screens/projectDetails/eagle-wings';
import Project15Details from './screens/projectDetails/rumi-sir';
import Project16Details from './screens/projectDetails/annies-boutique';
import Project17Details from './screens/projectDetails/lgv-gas-systems';
import ProjectDetails from './screens/projectDetails/projectDetails';

const TRACKING_ID = "G-GQE2TFY87X"; 
ReactGA.initialize(TRACKING_ID);

ReactGA.send({ 
  hitType: "pageview", 
  page: window.location.pathname,
  title: "Custom Title"
});

const sectionVariants = {
  hidden: { opacity: 1, y: 50 },
  visible: { opacity: 1, y: 0 },
};

const ArrowUpIcon = () => (
  <svg
    className="arrow-up-icon"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <polyline points="5 12 12 5 19 12" transform="rotate(90 12 12)"></polyline>
    <line x1="12" y1="19" x2="12" y2="5" transform="rotate(90 12 12)"></line>
  </svg>
);

const ScrollToTop = () => {
  const [showButton, setShowButton] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;

      if (scrollTop > 0.60 * (documentHeight - windowHeight)) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      {showButton && (
        <div className="back-to-top" onClick={scrollToTop}>
          <ArrowUpIcon />
        </div>
      )}
    </>
  );
};

const App = () => {
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(true);
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    const fetchArticles = async () => {
      setProgress(30);
      try {
        const newsRef = collection(db, 'news');
        const querySnapshot = await getDocs(newsRef);
        const fetchedArticles = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setArticles(fetchedArticles);
        setProgress(100);
      } catch (err) {
        console.error('Error fetching articles:', err);
        setProgress(100);
      } finally {
        setLoading(false);
      }
    };

    fetchArticles();
  }, []);

  const categorizeArticles = (category) => articles.filter(article => article.category.toLowerCase() === category.toLowerCase());

  return (
    <Router>
      <div className="App">
        {loading ? (
          <Preloader />
        ) : (
          <>
            <Navbar />
            {/* <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/work" element={<Work />} />
              <Route path="/team/:memberId" element={<TeamDetails />} />
              <Route path="/learn" element={<Learn articles={articles} />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/about" element={<About />} />
              <Route path="/ideal-farmers-yought-recognition-awards" element={<Project1Details />} />
              <Route path="/apprey-public-relations-pr" element={<Project2Details />} />
              <Route path="/silicon-mountain-conference-2023" element={<Project3Details />} />
              <Route path="/Lewa" element={<Project4Details />} />
              <Route path="/editi" element={<Project5Details />} />
              <Route path="/purpose" element={<Project6Details />} />
              <Route path="/alani" element={<Project7Details />} />
              <Route path="/mayen-spices" element={<Project8Details />} />
              <Route path="/ulevus-brand-book-version-1-0" element={<Project9Details />} />
              <Route path="/project10Details" element={<Project10Details />} />
              <Route path="/african-rooted" element={<Project11Details />} />
              <Route path="/echolink-solutions" element={<Project0Details />} />
              <Route path="/sontech" element={<Project12Details />} />
              <Route path="/house-of-sigala" element={<Project13Details />} />
              <Route path="/eagle-wings" element={<Project14Details />} />
              <Route path="/rumi-sir" element={<Project15Details />} />
              <Route path="/annies-boutique" element={<Project16Details />} />
              <Route path="/lgv-gas-systems" element={<Project17Details />} />
            </Routes> */}

            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/work" element={<Work />} />
              <Route path="/work/:slug" element={<ProjectDetails />} /> 
              <Route path="/team/:slug" element={<TeamDetails />} />
              <Route path="/ideas" element={<Learn articles={articles} />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/about" element={<About />} />
            </Routes>

            <Footer />
            <ScrollToTop />
          </>
        )}
      </div>
    </Router>
  );
};

export default App;
